<template>
  <div class="forgotPassword">
    <div class="forgotPassword-component-layout">
      <Form
          ref="formRef"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :model="formInfo"
          @submit="handleSubmit"
          @keydown.enter="handleSubmit">
        <FormItem
            label="手机号"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            name="account"
        >
          <Input
              type="text" allow-clear
              v-model:value="formInfo.account"
          />

        </FormItem>
        <FormItem
            label="新密码"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            name="newPassword"
        >
          <Input
              type="password" allow-clear
              v-model:value="formInfo.newPassword"
          />

        </FormItem>
        <FormItem
            label="确认新密码"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            name="confirmPassword"
        >
          <Input
              type="password" allow-clear
              v-model:value="formInfo.confirmPassword"
          />
        </FormItem>
        <FormItem
            label="验证码"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            name="authKey"
        >
          <Row :gutter="20">
            <Col :span="18">
              <Input
                  type="text" allow-clear
                  v-model:value="formInfo.authKey"
              />
            </Col>
            <Col :span="6">
              <img class="authImg" :src="authImage" @click="getAuthImage"/>
            </Col>

          </Row>
        </FormItem>
        <FormItem
            label="短信验证码"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            name="phone"
        >
          <Row :gutter="20">
            <Col :span="18">
              <Input
                  type="tel" allow-clear
                  v-model:value="formInfo.sms"
              />
            </Col>
            <Col :span="6">
              <Button type="primary" v-if="!issend" @click="getSendSms">获取验证码</Button>
              <Button type="primary" v-if="issend">{{count}}s</Button>
            </Col>

          </Row>
        </FormItem>
        <FormItem
            class="text-left"
            :wrapper-col="{ offset: 4, span: 20 }"
        >
          <Button
              type="primary"
              htmlType="submit"
              block
              size="large"
              style="border-radius: 5px;"
          >找回密码</Button>

        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script lang="ts">

import {
  defineComponent,
  ref,
  onMounted
} from 'vue'
import {ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { useRouter} from 'vue-router'
import { useStore } from '@/store'

import {fwdAuthImage, fwdPassword, fwdSendSms,} from '@/api/user'

import {setToken} from '@/utils/auth'
import {message} from 'ant-design-vue'
interface FormInfo {
  account: string;
  confirmPassword:string;
  newPassword:string;
  authKey:string;
  sms:string;
}
const phoneCheck = (rule:any, value:any, callback:any)=>{
  const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
  if( typeof (value) === 'undefined' || value === ''){
    return Promise.resolve()
  }else {
    if (!reg.test(value)) {
      // callback('请输入正确手机格式')
      return Promise.reject("请输入正确手机格式");
      // return
    }
  }
  return Promise.resolve()

}
export default defineComponent({
  name: 'ForgotPassword',
  props: {},
  setup() {
    const store = useStore()
    const router = useRouter()
    const formRef = ref();
    let formInfo = ref<FormInfo>({
      account:'',
      confirmPassword:'',
      newPassword:'',
      authKey:'',
      sms:''
    })
    const rules = ref({
      account:[
        { required: true, message: '请输入手机号', trigger: 'blur' },
        { validator:phoneCheck }
      ],
      newPassword:[
        { required: true, message: '请输入新密码', trigger: 'blur' },
      ],
      confirmPassword:[
        { required: true, message: '请输入确认新密码', trigger: 'blur' },
      ],
      authKey:[
        { required: true, message: '请输入验证码', trigger: 'blur' },
      ],
    })
    let phone = ref('')
    let  labelCol=ref({ span: 4 }),
        wrapperCol=ref( { span: 20 })
    let partLoading =ref(false)
    let authImage = ref('')
    let count = ref(60)
    let issend = ref(false)
    // let authKey:string = ''
    onMounted(()=>{
      getAuthImage()
    })

    //获取验证码信息
    const getAuthImage = ()=>{
      fwdAuthImage({}).then((res)=>{
        // console.log(res)
        let data = res.data
        authImage.value = 'data:image/jpeg;base64,'+data.authImage
        // authKey = data.authKey

      })
    }
    //倒计时
    const countDown= ()=>{
      if (count.value === 1) {//当为0的时候，liked设置为true，button按钮显示内容为 获取验证码
        issend.value=false
      } else {
        count.value = count.value-1
        setTimeout(() => countDown(), 1000)//每一秒调用一次
      }
    }

    const getSendSms=()=>{
      if(!formInfo.value.account){
        message.warning('请输入手机号')
        return
      }
      if(!formInfo.value.authKey){
        message.warning('请输入验证码')
        return
      }
      countDown()
      issend.value = true
      let data = {
        "phone": formInfo.value.account,
        "authKey": formInfo.value.authKey,
      }
      fwdSendSms(data).then((res)=>{
        // console.log(res)
        if (res.httpCode == 200){
          countDown()
        }
      })
    }


    //忘记密码修改密码
    const handleSubmit = () =>{
      // formRef.value.validate().then(()=>{
      var data ={
        "account": formInfo.value.account,
        "newPassword": formInfo.value.newPassword,
        "confirmPassword": formInfo.value.confirmPassword,
        "authKey": formInfo.value.authKey,
        "sms": formInfo.value.sms,
      }
      fwdPassword(data).then((res)=>{
        // console.log(res)
        if(res.httpCode == 200){
          message.success('修改成功')
          router.push('/login')
        }
      })
      // }).catch((error: ValidateErrorEntity<FormInfo>) => {
      //   console.log('error', error);
      // });
    }

    return {
      formInfo,
      labelCol,
      wrapperCol,
      partLoading,
      formRef,
      rules,
      authImage,
      count,
      issend,
      phone,
      handleSubmit,
      getAuthImage,
      getSendSms
    }

  },
})
</script>

<style lang="scss" scoped>
.forgotPassword {
  height: 100%;
  &-component-layout {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, .1);
    border-radius: 5px;
    width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -80%);
    ::v-deep .ant-form-item-label > label::after {
      content: '';
    }
  }
  .authImg {
    width: 100%;
    vertical-align: middle;
  }
}
</style>