

import {
  defineComponent,
  ref,
  onMounted
} from 'vue'
import {ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { useRouter} from 'vue-router'
import { useStore } from '@/store'

import {fwdAuthImage, fwdPassword, fwdSendSms,} from '@/api/user'

import {setToken} from '@/utils/auth'
import {message} from 'ant-design-vue'
interface FormInfo {
  account: string;
  confirmPassword:string;
  newPassword:string;
  authKey:string;
  sms:string;
}
const phoneCheck = (rule:any, value:any, callback:any)=>{
  const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
  if( typeof (value) === 'undefined' || value === ''){
    return Promise.resolve()
  }else {
    if (!reg.test(value)) {
      // callback('请输入正确手机格式')
      return Promise.reject("请输入正确手机格式");
      // return
    }
  }
  return Promise.resolve()

}
export default defineComponent({
  name: 'ForgotPassword',
  props: {},
  setup() {
    const store = useStore()
    const router = useRouter()
    const formRef = ref();
    let formInfo = ref<FormInfo>({
      account:'',
      confirmPassword:'',
      newPassword:'',
      authKey:'',
      sms:''
    })
    const rules = ref({
      account:[
        { required: true, message: '请输入手机号', trigger: 'blur' },
        { validator:phoneCheck }
      ],
      newPassword:[
        { required: true, message: '请输入新密码', trigger: 'blur' },
      ],
      confirmPassword:[
        { required: true, message: '请输入确认新密码', trigger: 'blur' },
      ],
      authKey:[
        { required: true, message: '请输入验证码', trigger: 'blur' },
      ],
    })
    let phone = ref('')
    let  labelCol=ref({ span: 4 }),
        wrapperCol=ref( { span: 20 })
    let partLoading =ref(false)
    let authImage = ref('')
    let count = ref(60)
    let issend = ref(false)
    // let authKey:string = ''
    onMounted(()=>{
      getAuthImage()
    })

    //获取验证码信息
    const getAuthImage = ()=>{
      fwdAuthImage({}).then((res)=>{
        // console.log(res)
        let data = res.data
        authImage.value = 'data:image/jpeg;base64,'+data.authImage
        // authKey = data.authKey

      })
    }
    //倒计时
    const countDown= ()=>{
      if (count.value === 1) {//当为0的时候，liked设置为true，button按钮显示内容为 获取验证码
        issend.value=false
      } else {
        count.value = count.value-1
        setTimeout(() => countDown(), 1000)//每一秒调用一次
      }
    }

    const getSendSms=()=>{
      if(!formInfo.value.account){
        message.warning('请输入手机号')
        return
      }
      if(!formInfo.value.authKey){
        message.warning('请输入验证码')
        return
      }
      countDown()
      issend.value = true
      let data = {
        "phone": formInfo.value.account,
        "authKey": formInfo.value.authKey,
      }
      fwdSendSms(data).then((res)=>{
        // console.log(res)
        if (res.httpCode == 200){
          countDown()
        }
      })
    }


    //忘记密码修改密码
    const handleSubmit = () =>{
      // formRef.value.validate().then(()=>{
      var data ={
        "account": formInfo.value.account,
        "newPassword": formInfo.value.newPassword,
        "confirmPassword": formInfo.value.confirmPassword,
        "authKey": formInfo.value.authKey,
        "sms": formInfo.value.sms,
      }
      fwdPassword(data).then((res)=>{
        // console.log(res)
        if(res.httpCode == 200){
          message.success('修改成功')
          router.push('/login')
        }
      })
      // }).catch((error: ValidateErrorEntity<FormInfo>) => {
      //   console.log('error', error);
      // });
    }

    return {
      formInfo,
      labelCol,
      wrapperCol,
      partLoading,
      formRef,
      rules,
      authImage,
      count,
      issend,
      phone,
      handleSubmit,
      getAuthImage,
      getSendSms
    }

  },
})
